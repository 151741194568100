import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { apiRequest } from '../Helpers/Api';

import MainContext from '../../Context';

import env from '../../env.json';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function Document() {
    const params = useParams();
    const [document, setDocument] = useState('');

    const fetchPage = useCallback(async () => {
        const documentData = (await apiRequest(`/documents?populate=media&filters[slug][$eq]=${params.slug}`)).data.data.data[0];
        if (documentData) {
            setDocument(env.url.concat(documentData.attributes.media.data.attributes.url));
        }
    }, []);

    useEffect(() => {
        fetchPage();
    });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            {/* <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=https://vsdg-api.vsdgalumni.lt/uploads/prasymo_sablonas_dd26a2cf0c.doc'} className="w-full h-screen" title="description" /> */}
            {document !== undefined ? (
                <div className="w-full h-screen">
                    <a download href={document} className="absolute text-6xl m-2 text-center text-green hover:text-brown cursor-pointer transition duration-300">
                        <FontAwesomeIcon icon={solid('file-arrow-down')} />
                    </a>
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src='.concat(document)} className="w-full h-screen" title="description" />
                </div>
            ) : (
                <div className="text-center pt-20">Nėra dokumento</div>
            )}
        </>
    );
}
