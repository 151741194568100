import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../Helpers/Api';

import { useIdleTimer } from 'react-idle-timer';

import MainContext from '../../Context';

import daukantasImg from '../../images/daukantas.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function Popups() {
    const { lang, isDaukantas, setIsDaukantas, scroll, device } = useContext(MainContext);

    const [daukantas, setDaukantas] = useState(false);

    const onIdle = () => {
        if (!isDaukantas) {
            setDaukantas(true);
            setIsDaukantas(true);
        }
    };

    const onActive = () => {
        setTimeout(() => setDaukantas(false), 3000);
    };

    const {} = useIdleTimer({
        onIdle,
        onActive,
        timeout: 60000 * 5,
        promptTimeout: 0,
        events: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove', 'visibilitychange'],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false,
    });

    return (
        <>
            {device === 'computer' ? (
                <div id="daukantas" className={'z-[60] fixed top-[300px] transform duration-1000 '.concat(daukantas ? 'right-0' : '-right-[200px]')}>
                    <Link className="cursor-pointer pointer-events-auto " to={lang === 'lt' ? '/lt/apie-s-daukantą' : ''}>
                        <img className="relative w-[48px] h-[153px] float-right scale-x-[-1]" alt="" src={daukantasImg}></img>
                        <div className="relative bg-white rounded top-5 px-1 text-sm w-auto float-right left-15">{lang === 'en' ? "Glad You're still here" : 'Malonu, kad Jūs dar čia'}</div>
                    </Link>
                </div>
            ) : (
                <></>
            )}

            {scroll > 700 && (window.location.href.split('/')[3] === 'projects' || window.location.href.split('/')[3] === 'events' || window.location.href.split('/')[3] === 'galleries') ? (
                <div
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    className="z-[60] fixed w-[50px] md:w-[70px] md:h-[60px] lg:w-[70px] lg:h-[70px] bg-nude bg-opacity-50 bottom-0 right-0 mb-4 mr-4 rounded-full table hover:bg-opacity-75 duration-200 "
                >
                    <div className="relative text-center align-middle table-cell opacity-75 sm:text-[30px] md:text-[35px] lg:text-[40px] text-brown hover:text-black duration-200 cursor-pointer hover:scale-x-100">
                        <FontAwesomeIcon icon={solid('arrow-up')}></FontAwesomeIcon>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
