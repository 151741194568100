import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { apiRequest } from '../Helpers/Api';

import MainContext from '../../Context';
import env from '../../env.json';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

//logos
import VSDG_logo_Default from '../../images/logos/VSDG_text-logo.webp';

export default function Header({ structuredData }) {
    const { device, lang, setLangDropdown, searchDropside, setSearchDropside, setMainIsLoading, menu, setMenu } = useContext(MainContext);

    const [loaded, setLoaded] = useState(false);

    const [VSDG_logo, setVSDG_logo] = useState(null);

    const uniformText = (text) => {
        text = text.toLowerCase();
        text = text.replaceAll(`ą`, `a`);
        text = text.replaceAll(`č`, `c`);
        text = text.replaceAll(`ę`, `e`);
        text = text.replaceAll(`ė`, `e`);
        text = text.replaceAll(`į`, `i`);
        text = text.replaceAll(`š`, `s`);
        text = text.replaceAll(`ų`, `u`);
        text = text.replaceAll(`ū`, `u`);
        text = text.replaceAll(`ž`, `z`);
        return text;
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const toggleExtendedMenu = () => {
        setMenu(!menu);
        setLangDropdown(false);
        setSearchDropside(false);
    };

    useEffect(() => {
        setMenu(false);
    }, [device, setMenu]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [documents, setDocuments] = useState([]);

    const toggleSearchBar = () => {
        if (searchDropside === true) {
            document.activeElement.blur();
            document.getElementById('searchbar_input').value = '';
        } else {
            document.getElementById('searchbar_input').focus();
        }

        setSearchDropside(!searchDropside);
        setSearchInput('');
        setSearchedItems([]);
        setMenu(false);
        if (documents === null) fetchDocuments();
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [mainMenu, setMainMenu] = useState([]);
    const [searchables, setSearchables] = useState([]);

    const fetchDocuments = useCallback(async () => {
        const documentsRequest = (await apiRequest(`/documents?locale=${lang}&populate=media&sort[0]=priority&filters[DocumentCategory][$ne]=-1`)).data.data.data;

        if (!documentsRequest.error) {
            setDocuments(
                documentsRequest.map((document) => ({
                    name: document.attributes.media.data.attributes.name,
                    slug: document.attributes.slug,
                })),
            );
            setSearchables(
                searchables.concat(
                    documentsRequest.map((document) => ({
                        name: document.attributes.media.data.attributes.name,
                        slug: document.attributes.slug,
                    })),
                ),
            );
        }
    }, [lang, searchables]);

    const fetchLogo = useCallback(async () => {
        const data = await apiRequest('/VSDG-logo?populate=media');
        if (!data.error) {
            setVSDG_logo(data.data.data.data.attributes.media.data.attributes.url);
        }
    }, []);

    useEffect(() => {
        fetchLogo();
    }, []);

    useEffect(() => {
        if (searchDropside && documents.length === 0) fetchDocuments();
    }, [lang, searchDropside, documents.length]);

    const fetchData = useCallback(async () => {
        const allMenu = (
            await apiRequest(
                `/menus?locale=${lang}&sort[0]=priority&filters[header][$eq]=true&fields[0]=priority&fields[1]=name&fields[2]=slug&fields[3]=header&populate[menus][sort][0]=priority&populate[menus][fields][0]=name&populate[menus][fields][1]=slug&&populate[menus][populate][menus][fields][0]=name&populate[menus][populate][menus][fields][0]=slug`,
            )
        ).data.data.data;

        const mainMenuItems = allMenu.filter((item) => item.attributes.header === true && item.attributes.priority > 0);

        if (!mainMenuItems.error) {
            setMainMenu(mainMenuItems.map((menu) => ({ name: menu.attributes.name, slug: menu.attributes.slug, menus: menu.attributes.menus.data })));
        }

        let searchableMenuItems = [];
        for (const headerMenu of allMenu) {
            if (headerMenu.attributes.slug) {
                searchableMenuItems = searchableMenuItems.concat({ name: headerMenu.attributes.name, slug: headerMenu.attributes.slug, type: 'subMenu' });
            }
            if (headerMenu.attributes.menus.data.length > 0) {
                searchableMenuItems = searchableMenuItems.concat(headerMenu.attributes.menus.data.map((menu) => ({ name: menu.attributes.name, slug: menu.attributes.slug, type: 'subMenu' })));
                for (const headerSubMenu of headerMenu.attributes.menus.data) {
                    if (headerSubMenu.attributes.menus.data.length > 0) {
                        searchableMenuItems = searchableMenuItems.concat(
                            headerSubMenu.attributes.menus.data.map((menu) => ({ name: menu.attributes.name, slug: menu.attributes.slug, type: 'subMenu' })),
                        );
                    }
                }
            }
        }
        if (!searchableMenuItems.error) {
            setSearchables(searchables.concat(searchableMenuItems));
        }

        setLoaded(true);
    }, [lang, searchables]);

    useEffect(() => {
        fetchData();
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [searchedItems, setSearchedItems] = useState([]);

    const [searchInput, setSearchInput] = useState('');

    const search = () => {
        let j = 0;
        const input = searchInput;
        const searches = [];
        if (input !== '' && input !== undefined) {
            for (var i = 0; i < searchables.length; i++) {
                if (j === 6) {
                    setSearchedItems(searches);
                    return;
                }

                if (uniformText(searchables[i].name).includes(input)) {
                    searches[j] = searchables[i];
                    j++;
                }
            }
            if (j === 0) {
                setSearchedItems([{ name: 'not found' }]);
            } else {
                setSearchedItems(searches);
            }
        } else {
            setSearchedItems([]);
        }
    };

    useEffect(() => {
        search();
    }, [searchInput]);

    const [path, setPath] = useState(window.location.pathname.split('/')[2]);

    const location = useLocation();

    useEffect(() => {
        setPath(window.location.pathname.split('/')[2]);
    }, [location.pathname]);

    //setPath(window.location.pathname.split('/')[2]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <button
                aria-label="Suskleisti mobilaus telefono navigacinę juostą"
                className={'z-40 fixed w-full h-full backdrop-brightness-50 '.concat(!menu && 'hidden')}
                onClick={() => {
                    toggleExtendedMenu();
                }}
            />
            <nav className={'z-50 fixed bg-white w-72 h-full duration-300 overflow-y-scroll -translate-x-72 '.concat(menu && 'translate-x-0')}>
                <button
                    aria-label="Suskleisti mobilaus telefono navigacinę juostą"
                    className="mx-10 my-6"
                    onClick={() => {
                        toggleExtendedMenu();
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
                        {' '}
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                </button>
                <ul className="my-4">
                    {mainMenu.map((mobileMenu, mobileMenu_index) => (
                        <li key={mobileMenu_index} className="group w-full bg-white">
                            {mobileMenu.slug === '' || mobileMenu.slug === null ? (
                                <>
                                    <button aria-label={'Išplėsti ' + mobileMenu.name} className="w-full h-12 px-10 py-2 text-left font-semibold">
                                        <span className="float-left">{mobileMenu.name}</span>
                                        <span className="group-hover:rotate-90 group-focus-within:rotate-90 text-xl font-bold float-right duration-300">{'>'}</span>
                                    </button>
                                    <ul className="secondList inherit w-full h-full bg-white overflow-hidden">
                                        {mobileMenu.menus.map((menu, submenu_index) => (
                                            menu.attributes.menus.data.length > 0?
                                                <li key={submenu_index} className="bg-white">
                                                    <Link
                                                        className="inline-block w-full pl-16 pr-10 py-2"
                                                        to={menu.attributes.slug+"/"+menu.attributes.menus.data[0].attributes.slug}
                                                        onClick={() => {
                                                            window.scrollTo(0, 0);
                                                            setMainIsLoading(true);
                                                            setMenu(false);
                                                            document.activeElement.blur();
                                                        }}
                                                    >
                                                        {menu.attributes.name}
                                                    </Link>
                                                </li>
                                            :
                                                <li key={submenu_index} className="bg-white">
                                                    <Link
                                                        className="inline-block w-full pl-16 pr-10 py-2"
                                                        to={menu.attributes.slug}
                                                        onClick={() => {
                                                            window.scrollTo(0, 0);
                                                            setMainIsLoading(true);
                                                            setMenu(false);
                                                            document.activeElement.blur();
                                                        }}
                                                    >
                                                        {menu.attributes.name}
                                                    </Link>
                                                </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <>
                                    <Link
                                        className="inline-block w-full px-10 py-2 font-semibold"
                                        to={mobileMenu.slug}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            setMainIsLoading(true);
                                            setMenu(false);
                                            document.activeElement.blur();
                                        }}
                                    >
                                        {mobileMenu.name}
                                    </Link>
                                </>
                            )}
                            <div className="h-0.5 bg-green opacity-50" />
                        </li>
                    ))}
                </ul>
            </nav>
            <header className="z-30 sticky top-0 shadow">
                <nav id="navigation" className={'h-[60px] md:h-[100px] lg:h-[120px] px-4 flex bg-offwhite'}>
                    <div className="my-auto float-left ">
                        <Link itemProp="logo" id="logo" aria-label="Sugrįžti į titulinį puslapį" className="hidden md:block hover:brightness-75 duration-300" to={'/' + lang}>
                            {VSDG_logo === null ? (
                                <img id="VSDG_logo" alt="Vilniaus Simono Daukanto gimnzaijos logotipas" className="w-24 lg:w-28 h-24 lg:h-28 object-center object-contain" src={VSDG_logo_Default} />
                            ) : (
                                <img
                                    id="VSDG_logo"
                                    alt="Vilniaus Simono Daukanto gimnzaijos logotipas"
                                    className="w-24 lg:w-28 h-24 lg:h-28 object-center object-contain"
                                    src={`${env.url}${VSDG_logo}`}
                                />
                            )}
                        </Link>
                        <Link itemProp="logo" id="home" aria-label="Sugrįžti į titulinį puslapį" className="cursor-pointer text-3xl text-green md:hidden" to={'/' + lang}>
                            <FontAwesomeIcon icon={solid('house')} />
                        </Link>
                    </div>
                    <ul className="relative my-auto text-center flex-grow hidden lg:block">
                        {mainMenu.length !== 0 ? (
                            <>
                                {mainMenu.map((mainMenu, menu_index) => (
                                    <li key={menu_index} className="group relative inline-block hover:bg-white min-w-[128px] mx-1  focus-within:shadow-sm rounded">
                                        {mainMenu.slug === '' || mainMenu.slug === null ? (
                                            <>
                                                <button
                                                    aria-label={'Išplėsti ' + mainMenu.name}
                                                    onClick={() => document.activeElement.blur()}
                                                    className="delay w-full p-2 group-hover:bg-white group-focus-within:bg-white rounded-t"
                                                >
                                                    {mainMenu.name}
                                                </button>
                                                <ul className="secondList z-50 absolute w-full overflow-hidden group-hover:shadow-sm group-focus-within:shadow-sm rounded-b ">
                                                    {mainMenu.menus.map((menu, submenu_index) => (
                                                        <li key={submenu_index} className="bg-white hover:bg-offwhite focus-within:bg-offwhite last:rounded-b duration-200">
                                                            {menu.attributes.menus.data.length > 0?
                                                                <Link
                                                                    className="block px-1 py-2 font-bold text-sm text-brown hover:text-darkBrown focus:text-darkBrown"
                                                                    to={menu.attributes.slug+"/"+menu.attributes.menus.data[0].attributes.slug}
                                                                    onClick={() => {
                                                                        window.scrollTo(0, 0);
                                                                        setMainIsLoading(true);
                                                                        document.activeElement.blur();
                                                                    }}
                                                                >
                                                                    {menu.attributes.name}
                                                                </Link>
                                                            :
                                                                <Link
                                                                    className="block px-1 py-2 font-semibold text-sm text-[#706540] hover:text-darkBrown focus:text-darkBrown"
                                                                    to={menu.attributes.slug}
                                                                    onClick={() => {
                                                                        window.scrollTo(0, 0);
                                                                        setMainIsLoading(true);
                                                                        document.activeElement.blur();
                                                                    }}
                                                                >
                                                                    {menu.attributes.name}
                                                                </Link>}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <Link
                                                className="w-full p-2 inline-block hover:bg-white focus-within:bg-white hover:shadow-sm rounded"
                                                to={mainMenu.slug}
                                                onClick={() => {
                                                    window.scrollTo(0, 0);
                                                    setMainIsLoading(true);
                                                    document.activeElement.blur();
                                                }}
                                            >
                                                {mainMenu.name}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </>
                        ) : (
                            <>
                                {Array.apply(0, Array(7)).map(function (menuPlaceholder, menuPlaceHolderIndex) {
                                    return (
                                        <li key={menuPlaceHolderIndex} className="group relative inline-block bg-white min-w-[128px] mx-1  focus-within:shadow-sm rounded">
                                            <div className="min-w-[128px] h-12 p-2" />
                                        </li>
                                    );
                                })}
                            </>
                        )}
                    </ul>
                    <button aria-label="Išplėsti mobilaus tlefono navigacinę juostą" onClick={(e) => toggleExtendedMenu()}>
                        <FontAwesomeIcon icon={solid('bars')} className="relative mx-5 my-auto text-4xl cursor-pointer text-green lg:hidden" />
                    </button>
                    <div
                        id="searchbar"
                        className={'z-30 w-12 h-12 mx-2 my-auto ml-auto transition-colors duration-[0.4s] cursor-pointer '
                            .concat(searchDropside ? 'bg-white drop-shadow rounded-tr-[1.5rem] ' : 'hover:bg-white hover:drop-shadow rounded-full ')
                            .concat(searchDropside && !searchInput && 'rounded-br-[1.5rem]')}
                    >
                        <div className="z-10 absolute w-52 -translate-x-52">
                            <input
                                id="searchbar_input"
                                placeholder="Paieška"
                                autoComplete="off"
                                onChange={(e) => {
                                    setSearchInput(uniformText(e.target.value.toLowerCase()));
                                }}
                                type="text"
                                className={'absolute h-12 py-6 right-0 bg-white rounded-tl-[1.5rem] focus:outline-none '
                                    .concat(searchDropside ? 'pl-5 w-52 ' : 'w-0 opacity-0 ')
                                    .concat(searchDropside && !searchInput && 'rounded-bl-[1.5rem]')}
                            />
                            {searchDropside && (
                                <>
                                    <nav className="w-[16rem] rounded-b mt-12 overflow-hidden">
                                        {searchedItems.map((searchedItem, index) => (
                                            <div
                                                key={index}
                                                className={'w-full bg-white duration-[0.4s] last:rounded-b-[1.5rem] last:pb-3 transition-colors '.concat(
                                                    searchedItem.name === 'not found' ? 'cursor-default' : 'hover:bg-offwhite',
                                                )}
                                            >
                                                <div className="h-[1px] mx-[-20px] bg-nude rounded-b" />
                                                {searchedItem.name === 'not found' ? (
                                                    <span className="px-5">{lang === 'en' ? 'Not found' : 'Nerasta'} </span>
                                                ) : (
                                                    <>
                                                        {searchedItem.slug.endsWith('docx') ||
                                                        searchedItem.slug.endsWith('doc') ||
                                                        searchedItem.slug.endsWith('xlsx') ||
                                                        searchedItem.slug.endsWith('xls') ||
                                                        searchedItem.slug.endsWith('pptx') ? (
                                                            <Link
                                                                className="inline-block w-full px-5"
                                                                to={'/dokumentas/' + searchedItem.slug}
                                                                target={searchedItem.type === 'subMenu' ? '' : '_blank'}
                                                                rel="noreferrer"
                                                                onClick={() => {
                                                                    window.scrollTo(0, 0);
                                                                    toggleSearchBar();
                                                                }}
                                                            >
                                                                {searchedItem.name}
                                                            </Link>
                                                        ) : searchedItem.slug.endsWith('pdf') ? (
                                                            <a
                                                                className="inline-block w-full px-5"
                                                                href={env.url + '/uploads/' + searchedItem.slug}
                                                                target={searchedItem.type === 'subMenu' ? '' : '_blank'}
                                                                rel="noreferrer"
                                                                onClick={() => {
                                                                    window.scrollTo(0, 0);
                                                                    toggleSearchBar();
                                                                }}
                                                            >
                                                                {searchedItem.name}
                                                            </a>
                                                        ) : (
                                                            <Link
                                                                className="inline-block w-full px-5"
                                                                to={searchedItem.slug}
                                                                target={searchedItem.type === 'subMenu' ? '' : '_blank'}
                                                                rel="noreferrer"
                                                                onClick={() => {
                                                                    window.scrollTo(0, 0);
                                                                    toggleSearchBar();
                                                                }}
                                                            >
                                                                {searchedItem.name}
                                                            </Link>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </nav>
                                </>
                            )}
                        </div>

                        <FontAwesomeIcon
                            id="searchbar_button"
                            icon={solid('search')}
                            onClick={(e) => toggleSearchBar()}
                            alt=""
                            className="z-50 text-green max-w-[26px] min-w-[26px] p-3 max-h-[24px] min-h-[24px]"
                        ></FontAwesomeIcon>
                    </div>
                </nav>
                {path === undefined ? (
                    <div className="z-20 py-1 align-middle bg-green text-center text-white text-base hidden md:block">
                        <span className="mr-1 ml-2">
                            <FontAwesomeIcon icon={solid('location-dot')} />
                        </span>
                        <a href="https://goo.gl/maps/yorosy8p4cjD4VZG8" target="_blank" rel="noreferrer" className="mr-2 ml-1">
                            {structuredData.about.address.streetAddress}, {structuredData.about.address.addressRegion}-{structuredData.about.address.postalCode}{' '}
                            {structuredData.about.address.addressLocality}
                        </a>

                        <span className="mr-1 ml-2">
                            <FontAwesomeIcon icon={solid('phone')} />
                        </span>
                        <span href="tel:+37052496517" className="mr-2 ml-1 hover:underline md:hover:no-underline">
                            {structuredData.about.telephone}
                        </span>

                        <span className="mr-1 ml-2">
                            <FontAwesomeIcon icon={solid('envelope')} />
                        </span>
                        <a href="mailto:rastine@daukanto.vilnius.lm.lt" className="mr-2 ml-1 hover:underline">
                            {structuredData.about.email}
                        </a>
                    </div>
                ) : (
                    <></>
                )}
            </header>
        </>
    );
}
