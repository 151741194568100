import { useCallback, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../Helpers/Api';

import MainContext from '../../../Context';

export default function NotFound() {
    const { lang } = useContext(MainContext);

    return (
        <div>
            <h1 className="text-center text-darkBrown text-8xl font-bold">404</h1>
            {
                //daukanto sad image
            }
            <p className="text-center text-darkBrown text-2xl">{lang === 'en' ? 'Page not found' : 'Puslapis nerastas'}</p>
            <div className="text-center">
                <Link to={'/' + lang} className="text-darkBrown text-lg underline">
                    <span>{lang === 'en' ? 'Click here to go to main page' : 'Spausk čia, kad patektum į pagrindinį puslapį'}</span>
                </Link>
            </div>
        </div>
    );
}
