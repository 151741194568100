import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../Helpers/Api';

import env from '../../../env.json';

import MainContext from '../../../Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function MainPage() {
    useEffect(() => {
        document.title = 'Vilniaus Simono Daukanto gimnazija';
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const { lang } = useContext(MainContext);
    const [bannerImages, setBannerImages] = useState([]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [news, setNews] = useState([]);

    const fetchNews = useCallback(async () => {
        const newsData = (await apiRequest(`/news?locale${lang}&sort[0]=priority%3Adesc&sort[1]=createdAt%3Adesc&fields[0]=name&fields[1]=link&populate[media][fields]=url&pagination[limit]=10`)).data
            .data.data;

        if (!newsData.error && newsData.length > 0) {
            setNews(newsData.map((data) => ({ name: data.attributes.name, link: data.attributes.link, media: data.attributes.media.data, priority: data.attributes.priority })));
        } else if (!newsData.error && newsData.length === 0) {
            setNews(0);
        }
    }, [lang]);

    useEffect(() => {
        fetchNews();
    }, [fetchNews]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [events, setEvents] = useState([]);

    const fetchEvents = useCallback(async () => {
        const eventsData = (await apiRequest(`/events?pagination[limit]=3&locale=${lang}&sort[0]=date%3ADesc&fields[0]=name&fields[1]=date&populate=lowSMedia`)).data.data.data;

        if (!eventsData.error) {
            setEvents(
                eventsData.map((data) => ({
                    id: data.id,
                    name: data.attributes.name,
                    date: data.attributes.date,
                    photo: data.attributes.lowSMedia.data,
                })),
            );
        }

        const announcementData = (await apiRequest(`/announcement?fields[0]=media&populate[media][fields][0]=url`)).data.data.data;
        if (announcementData.attributes.media.data !== null) setAnnouncement(announcementData.attributes.media.data.attributes.url);
    }, [lang]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [galleries, setGallery] = useState([]);

    const fetchGallery = useCallback(async () => {
        const galleryData = (await apiRequest(`/galleries?pagination[limit]=3&locale=lt&sort[0]=createdAt%3ADesc&fields[0]=name&fields[1]=createdAt&populate=media`)).data.data.data;

        if (!galleryData.error) {
            setGallery(
                galleryData.map((data) => ({
                    id: data.id,
                    name: data.attributes.name,
                    photo: data.attributes.media.data[0],
                })),
            );
        }
    }, [lang]);

    useEffect(() => {
        fetchGallery();
    }, [fetchGallery]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [flag, setFlag] = useState(null);
    const [announcement, setAnnouncement] = useState(null);

    const fetchData = useCallback(async () => {
        const bannerImageData = (await apiRequest(`/background-image?fields[0]=media&populate[media][fields][0]=url`)).data.data.data;
        if (!bannerImageData.error) {
            setBannerImages(bannerImages.concat(bannerImageData.attributes.media.data.map((data) => env.url + data.attributes.url)));
        }

        const tempFlag = await apiRequest(`/flag`);
        if (!tempFlag.error) {
            setFlag(tempFlag.data.data.data.attributes.Flag);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (photoIndex === bannerImages.length - 1) setPhotoIndex(0);
            else setPhotoIndex((photoIndex) => photoIndex + 1);
        }, 10000);
        return () => clearInterval(interval);
    }, [photoIndex, bannerImages.length]);

    return (
        <>
            <div className="relative w-[calc(100%+18px)] h-[20rem] md:h-[30rem] mt-[-30px] mx-[-10px] text-center">
                <div id="flag_ornaments" className={flag ? 'opacity-100' : 'opacity-0'}>
                    <div id="top_lt_flag_ornament" role="img" alt="Lietuvos vėliavos ornamentas" className="flag z-10 absolute w-full h-6 top-0" />
                    <div id="top_lt_flag_ornament" role="img" alt="Lietuvos vėliavos ornamentas" className="flag z-10 absolute w-full h-6 bottom-0" />
                </div>

                <div className={'z-10 absolute w-full h-full flex flex-col duration-700 '.concat(photoIndex === 0 ? 'opacity-100 delay-[600ms]' : 'opacity-0 delay-100')}>
                    <span className="m-auto text-white text-2xl md:text-5xl ">{lang === 'en' ? 'Vilnius Simonas Daukantas gymnasium' : 'Vilniaus Simono Daukanto gimnazija'}</span>
                </div>
                <div className={'z-10 absolute w-full h-full flex flex-col duration-500 '.concat(photoIndex === 1 ? 'font-bold opacity-100 delay-300' : 'opacity-0 delay-[600ms]')}>
                    <span className="relative mx-auto mt-auto md:text-2xl italic text-white select-none">
                        {lang === 'en' ? 'Knowledge and wittiness brings great and everlasting honour to a family' : 'Mokslas ir gudrybė giminės yra amžina ir visų didžiausia garbė'}
                    </span>
                    <span className="relative mx-auto mb-6 md:text-xl text-white select-none duration-500 font-bold">Simonas Daukantas</span>
                </div>
                {bannerImages.map((backgroundImage, backgroundImageIndex) => (
                    <img
                        key={backgroundImageIndex}
                        className={'z-0 absolute w-full h-full object-cover brightness-[0.7] duration-700 '.concat(
                            backgroundImageIndex === photoIndex ? 'opacity-100' : 'opacity-0 delay-500',
                        )}
                        src={backgroundImage}
                        alt={backgroundImageIndex + ' Iliustracija kuri puošią titulinį tinklalpio puslapį'}
                    />
                ))}
                <div className={'z-0 absolute w-full h-full delay-100 duration-500 '.concat(!bannerImages.length > 0 && 'bg-offwhite')}></div>
            </div>

            <article className="container relative mx-auto my-4 grid grid-cols-1 md:grid-cols-4 gap-4">
                <Link
                    className="text-center rounded bg-white w-4/5 md:w-2/3 mx-auto py-2 shadow md:shadow-none hover:shadow focus:shadow hover:brightness-110 duration-200"
                    to="./mokiniams"
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                >
                    <FontAwesomeIcon icon={solid('graduation-cap')} className="text-green text-3xl md:text-4xl"></FontAwesomeIcon>
                    <p className="mx-auto text-green text-3xl md:text-2xl">{lang === 'en' ? 'For students' : 'Mokiniams'}</p>
                </Link>
                <Link
                    className="text-center rounded bg-white w-4/5 md:w-2/3 mx-auto py-2 shadow md:shadow-none hover:shadow hover:brightness-110 duration-200"
                    to="./tėvams"
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                >
                    <FontAwesomeIcon icon={solid('house-user')} className="text-green text-3xl md:text-4xl"></FontAwesomeIcon>
                    <p className="mx-auto text-green text-3xl md:text-2xl">{lang === 'en' ? 'For parents' : 'Tėvams'}</p>
                </Link>
                <Link
                    className="text-center rounded bg-white w-4/5 md:w-2/3 mx-auto py-2 shadow md:shadow-none hover:shadow hover:brightness-110 duration-200"
                    to="./mokytojams"
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                >
                    <FontAwesomeIcon icon={solid('person-chalkboard')} className="text-green text-3xl md:text-4xl"></FontAwesomeIcon>
                    <p className="mx-auto text-green text-3xl md:text-2xl">{lang === 'en' ? 'For teachers' : 'Mokytojams'}</p>
                </Link>
                <Link
                    className="text-center rounded bg-white w-4/5 md:w-2/3 mx-auto py-2 shadow md:shadow-none hover:shadow hover:brightness-110 duration-200"
                    to="./partneriams"
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                >
                    <FontAwesomeIcon icon={solid('handshake')} className="text-green text-3xl md:text-4xl"></FontAwesomeIcon>
                    <p className="mx-auto text-green text-3xl md:text-2xl">{lang === 'en' ? 'Partners' : 'Partneriai'}</p>
                </Link>
            </article>

            {announcement !== null ? (
                <div className="container w-full mx-auto">
                    <img className="w-full" src={env.url + announcement} alt="Announcement" />
                </div>
            ) : (
                <></>
            )}
            <article className="container mx-auto my-10 lg:my-20">
                <h1>{lang === 'en' ? 'News' : 'Naujienos'}</h1>
                {news === 0 ? (
                    <div className="text-center text-2xl pt-8 font-bold ">{lang === 'lt' ? 'Kol kas naujienų nėra' : 'No news for now'}</div>
                ) : (
                    <ul className="news_columns w-full h-auto py-[10px] grid gap-4">
                        {news.length !== 0 ? (
                            <>
                                {news.map((newsArticle, newsArticleIndex) => (
                                    <li key={newsArticleIndex}>
                                        <a
                                            href={newsArticle.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={'group flex h-[8rem] md:h-[10rem] lg:h-[8rem] w-full text-[0.8rem] lg:text-[1rem] text-darkBrown rounded duration-200 '
                                                .concat(newsArticle.priority > 0 ? 'bg-green bg-opacity-20 ' : ' ')
                                                .concat(newsArticle.link === null ? '' : 'cursor-pointer shadow md:shadow-none hover:brightness-110 hover:shadow hover:text-black')}
                                        >
                                            <img
                                                className="w-0 h-0 md:w-12 md:h-12 md:mx-5 md:my-auto md:float-left"
                                                src={`${env.url}${newsArticle.media.attributes.url}`}
                                                alt="Icon to make a news article easier to read"
                                            />
                                            <div className="opacity-0 md:opacity-100 md:h-2/3 my-auto border border-green rounded-full"></div>
                                            <span className="flex-grow m-4 my-auto float-right align-middle multiline-ellipsis clamp-4">{newsArticle.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </>
                        ) : (
                            <>
                                {Array.apply(0, Array(10)).map(function (newsPlaceHolder, newsPlaceHolderIndex) {
                                    return (
                                        <li
                                            key={newsPlaceHolderIndex}
                                            className="group h-[8rem] md:h-[10rem] lg:h-[8rem] w-full place-items-stretch my-[1rem] md:my-[0.5rem] text-[1rem] text-darkBrown rounded"
                                        >
                                            <div className="relative z-10 w-1/3 h-full flex items-center float-left rounded-l">
                                                <div className="relative w-[3rem] h-[3rem] mx-auto bg-green rounded-full" />
                                                <div className="absolute h-2/3 w-1 border-r border-brown right-0"></div>
                                            </div>

                                            <div className="relative w-2/3 h-full flex items-center text-center float-right">
                                                <div className="relative px-2 py-2 flex items-center mx-auto text-[1rem] md:text-[1rem] lg:text-[1rem] text-darkBrown">***</div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </>
                        )}
                    </ul>
                )}
            </article>

            <article className="container mx-auto my-10 lg:my-20">
                <h1>{lang === 'en' ? 'Events' : 'Įvykiai'}</h1>
                <ul className="w-full h-full mt-6 grid grid-cols-2 lg:grid-cols-4 gap-y-2 gap-x-4">
                    {events.length > 0 ? (
                        <>
                            {events.map((eventArticle, eventArticleIndex) => (
                                <li key={eventArticleIndex}>
                                    <Link
                                        to={'./įvykiai/' + eventArticle.id}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                        className="relative inline-block w-full h-52 overflow-hidden rounded bg-green shadow md:shadow-none hover:shadow focus:shadow hover:brightness-125 duration-300 "
                                    >
                                        {eventArticle.photo ? (
                                            <img
                                                className="object-cover my-[-10px] w-96 h-[calc(100%+20px)] blur-sm brightness-50"
                                                src={env.url + eventArticle.photo.attributes.url}
                                                alt="Photo from an evnent"
                                            />
                                        ) : (
                                            <div className="w-full h-full bg-green brightness-90 saturate-[0.8]" />
                                        )}
                                        <div className="absolute w-full h-full p-2 top-0 flex flex-col text-white text-lg">
                                            <span className="mt-auto text-center multiline-ellipsis clamp-5">{eventArticle.name}</span>
                                            <span className="mb-auto w-full inline-block text-center font-bold">{eventArticle.date}</span>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </>
                    ) : (
                        <>
                            {Array.apply(0, Array(3)).map(function (eventPlaceholder, eventPlaceHolderIndex) {
                                return (
                                    <li key={eventPlaceHolderIndex}>
                                        <div className="group relative flex w-full h-52 bg-green bg-opacity-75 brighntess-75 overflow-hidden rounded" />
                                    </li>
                                );
                            })}
                        </>
                    )}
                    <li>
                        <Link
                            className="w-full h-52 flex"
                            to="./įvykiai"
                            onClick={() => {
                                window.scrollTo(0, 0);
                            }}
                        >
                            <span className="mx-auto my-auto text-green text-xl">{lang === 'en' ? 'More events ≫' : 'Daugiau įvykių ≫'}</span>
                        </Link>
                    </li>
                </ul>
            </article>

            <article className="container mx-auto mb-10 lg:mb-10">
                <h2 className="text-4xl">{lang === 'en' ? 'Gallery' : 'Galerija'}</h2>
                <ul className="w-full h-full mt-6 grid grid-cols-2 lg:grid-cols-4 gap-y-2 gap-x-4">
                    {galleries.length > 0 ? (
                        <>
                            {galleries.map((galleryArticle, galleryArticleIndex) => (
                                <li key={galleryArticleIndex}>
                                    <Link
                                        to={'./galerija/' + galleryArticle.id}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                        className="relative inline-block w-full h-32 overflow-hidden rounded bg-green shadow md:shadow-none hover:shadow focus:shadow hover:brightness-125 duration-300 "
                                    >
                                        {galleryArticle.photo ? (
                                            <img
                                                className="object-cover my-[-10px] w-96 h-[calc(100%+20px)] blur-sm brightness-50"
                                                src={env.url + galleryArticle.photo.attributes.url}
                                                alt="Photo from an evnent"
                                            />
                                        ) : (
                                            <div className="w-full h-full bg-green brightness-90 saturate-[0.8]" />
                                        )}
                                        <div className="absolute w-full h-full p-2 top-0 flex flex-col text-white text-lg">
                                            <span className="mt-auto text-center multiline-ellipsis clamp-5">{galleryArticle.name}</span>
                                            <span className="mb-auto w-full inline-block text-center font-bold">{galleryArticle.date}</span>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </>
                    ) : (
                        <>
                            {Array.apply(0, Array(3)).map(function (eventPlaceholder, eventPlaceHolderIndex) {
                                return (
                                    <li key={eventPlaceHolderIndex}>
                                        <div className="group relative flex w-full h-32 bg-green bg-opacity-75 brighntess-75 overflow-hidden rounded" />
                                    </li>
                                );
                            })}
                        </>
                    )}
                    <li>
                        <Link
                            className="w-full h-32 flex"
                            to="./galerija"
                            onClick={() => {
                                window.scrollTo(0, 0);
                            }}
                        >
                            <span className="mx-auto my-auto text-green text-xl">{lang === 'en' ? 'More events ≫' : 'Daugiau ≫'}</span>
                        </Link>
                    </li>
                </ul>
            </article>
        </>
    );
}
