import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useParams} from 'react-router-dom';
import { apiRequest } from '../../Helpers/Api';

import env from '../../../env.json';

import MainContext from '../../../Context';

import NotFound from '../NotFound';
import Loading from '../../SpinningCircle';

export default function Page() {
    const { lang } = useContext(MainContext);
    const params = useParams();
    const [page, setPage] = useState();

    const [thirdLayerMenus, setMenus] = useState([]);

    const [error, setError] = useState(2);
    //error 0 - no error
    //error 1 - no translation
    //error 2 - loading
    //error 3 - error
    //error 4 - no content

    const formatContent = (content) => {
        let fixedContent = content.replaceAll(`"/uploads`, `"${env.url}/uploads`);
        fixedContent = fixedContent.replaceAll(`,/uploads`, `,${env.url}/uploads`);
        fixedContent = fixedContent.replaceAll(`font-family:Tahoma`, ``);
        fixedContent = fixedContent.replaceAll(`href=`, `target="_blank" rel="noreferrer" href=`);
        return fixedContent;
    };
    const fetchPage = useCallback(async () => {
        setPage();
        setError(2);
        const isSecondLayer = encodeURIComponent(params.slug) === window.location.pathname.split('/')[2];

        const oldThirdLayerMenus = thirdLayerMenus
        const thirdLayerMenuData = (
            await apiRequest(`/menus?locale=${lang}&populate=section&populate=menus&filters[slug][$eq]=${window.location.pathname.split('/')[2]}&populate[menus][sort][0]=priority%3Aasc`)
        ).data.data.data;
        if(thirdLayerMenuData.length > 0 && thirdLayerMenuData[0] !== oldThirdLayerMenus){
            setMenus(thirdLayerMenuData[0].attributes.menus.data);
        }   

        let menuData = (await apiRequest(`/menus?locale=${lang}&populate=section&populate=menus&filters[slug][$eq]=${params.slug}`)).data.data.data;
        console.log()

        if (menuData.length > 0) {
            if (isSecondLayer) {
                setMenus(menuData[0].attributes.menus.data);
            }
            if (isSecondLayer && menuData[0].attributes.menus.data.length > 0) {
                setError(4);
                return;
            } else if (menuData[0].attributes.section.data !== null) {
                document.title = menuData[0].attributes.name;
                setPage(formatContent(menuData[0].attributes.section.data.attributes.content));
                setError(0);
            } else {
                menuData = (await apiRequest(`/menus?locale=lt&populate=section&populate=menus&filters[slug][$eq]=${params.slug}`)).data.data.data;
                if (menuData.length > 0) {
                    setPage(formatContent(menuData[0].attributes.section.data.attributes.content));
                    setError(1);
                } else {
                    setError(3);
                }
            }
        } else {
            setError(3);
        }
    }, [params.slug, lang]);

    useEffect(() => {
        fetchPage();
    }, [lang, fetchPage]);

    console.log(thirdLayerMenus);

    return (
        <>
            {error === 3 ? (
                <NotFound />
            ) : (
                <div className={'page '.concat(thirdLayerMenus.length > 0 ? 'm-[-8px] mt-[-30px]' : 'mt-[-110px]')}>
                    <nav className={'w-full '.concat(thirdLayerMenus.length > 0 ? 'bg-green bg-opacity-30' : 'bg-white')}>
                        <ul className="w-full text-center lg:flex lg:justify-evenly grid-cols-1 grid pt-12">
                            {thirdLayerMenus.map((thirdLayerMenus, index) => (
                                <li key={index}>
                                    <Link
                                        className={'text-center duration-50'}
                                        to={'/' + lang + '/' + window.location.pathname.split('/')[2] + '/' + thirdLayerMenus.attributes.slug}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        <span
                                            className={'text-xl lg:text-[16px] '.concat(
                                                thirdLayerMenus.attributes.slug === params.slug ? 'font-bold lg:rounded-t lg:p-[11px] lg:bg-white' : 'hover:text-brown text-darkBrown',
                                            )}
                                        >
                                            {thirdLayerMenus.attributes.name}
                                        </span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    {error === 1 && <div className="p-3 mb-5 bg-yellow text-center text-xl font-semibold uppercase rounded">{lang === 'en' ? 'Missing translation' : 'Trūksta vertimo'}</div>}
                    {error === 4 && <div className="p-3 mb-5 bg-yellow text-center text-xl font-semibold uppercase rounded">{lang === 'en' ? '^ Select sub-section ^' : '^ Pasirinkite puslapį ^'}</div>}
                    {error === 0 || error === 4 || (error === 1 && page) ? (
                        <div className="static ck-content overflow-hidden p-1 mt-8 container mx-auto m-[8px]" dangerouslySetInnerHTML={{ __html: page }}></div>
                    ) : (
                        <div className="mt-20">
                            <Loading />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
