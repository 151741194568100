import axios from 'axios';
import env from '../../env.json';

export const apiRequest = async (url, method, data) => {
    try {
        return {
            error: null,
            data: await axios({
                method: method,
                url: `${env.api}${url}`,
                data: data,
            }),
        };
    } catch (error) {
        return {
            data: null,
            error: error,
        };
    }
};
