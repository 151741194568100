import React, { useEffect, createContext, useState, useCallback } from 'react';

const MainContext = createContext();

export function ContextProvider({ children }) {
    const [langDropdown, setLangDropdown] = useState(false);
    const [searchDropside, setSearchDropside] = useState(false);
    const [menu, setMenu] = useState(false);

    /////////////////////////////////////////////

    const [lang, setLang] = useState(window.location.pathname.split('/')[1]);

    const [isDaukantas, setIsDaukantas] = useState(false);

    const changeLang = useCallback(async () => {
        setLang(window.location.pathname.split('/')[1]);
    }, [window.location.pathname.split('/')[1]]);

    useEffect(() => {
        changeLang();
    }, [changeLang]);

    /////////////////////////////////////////////

    const [year, setYear] = useState(0);

    useEffect(() => {
        const date = new Date();
        setYear(date.getMonth() >= 8 ? date.getFullYear() : date.getFullYear() - 1);
    }, []);

    /////////////////////////////////////////////

    const [scroll, setScroll] = useState(true);
    useEffect(() => {
        function logit() {
            setScroll(window.pageYOffset);
            setLangDropdown(false);
            setSearchDropside(false);
        }

        window.addEventListener('scroll', logit);

        return () => {
            window.removeEventListener('scroll', logit);
        };
    });

    /////////////////////////////////////////////

    const [device, setDevice] = useState();

    function logDevice() {
        if (window.innerWidth <= 768) {
            setDevice('phone');
        } else if (768 < window.innerWidth && window.innerWidth <= 1024) {
            setDevice('tablet');
        } else {
            setDevice('computer');
        }
    }

    useEffect(() => {
        logDevice();
    });

    useEffect(() => {
        window.addEventListener('resize', logDevice);

        return () => {
            window.removeEventListener('resize', logDevice);
        };
    });

    /////////////////////////////////////////////

    const [mainIsLoading, setMainLoadingStatus] = useState(false);

    const setMainIsLoading = (mainLoadingStatus) => {
        setMainLoadingStatus(mainLoadingStatus);
    };

    //////////////////////////////////////////////

    const [activeNumber, changeActiveNumber] = useState(null);

    const setActiveNumber = (number) => {
        changeActiveNumber(number);
    };

    return (
        <MainContext.Provider
            value={{
                scroll,
                lang,
                isDaukantas,
                setIsDaukantas,
                year,
                device,
                langDropdown,
                setLangDropdown,
                searchDropside,
                setSearchDropside,
                mainIsLoading,
                setMainIsLoading,
                menu,
                setMenu,
                activeNumber,
                setActiveNumber,
            }}
        >
            {children}
        </MainContext.Provider>
    );
}

export default MainContext;
