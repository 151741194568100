import React, { useState, useEffect, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { ContextProvider } from './Context';

import './App.css';

import Popups from './components/Popups/Popups';
import Header from './components/Header/Header';
//import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import Document from './components/Document/Document';

const Footer = lazy(() => import('./components/Footer/Footer'));

function App() {
    const [isLoadFooter, setIsLoadFooter] = useState(false);

    const handleScroll = () => {
        if (isLoadFooter === false && Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight) {
            setIsLoadFooter(true);
            window.removeEventListener('scroll', handleScroll);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        });
    });

    const structuredData =     {
        "@context": "https://schema.org",
        "@type": "WebSite",
        url: "https://www2389a.vu.lt/",
        author: {
            "@type": "Person",
            givenName: "Lukas",
            familyName: "Stavaris",
        },
        author: {
            "@type": "Person",
            givenName: "Matas",
            familyName: "Matulionis",
        },
        isFamilyFriendly: true,
        keywords: "Vilniaus Simono Daukanto gimnazija,VSDG,mokykla,Simonas Daukantas",
        sameAs: [
        "https://lt.wikipedia.org/wiki/Vilniaus_Simono_Daukanto_gimnazija",
        "https://lietuvai.lt/wiki/Vilniaus_Simono_Daukanto_gimnazija",
        ],
        about: {
            "@type": "HighSchool",
            name : "Vilniaus Simono Daukanto gimnazija",
            alternateName: "VSDG",
            slogan: "Mokslas ir gudrybė giminės yra amžina ir visų didžiausia garbė",
            address: {
                "@type": "PostalAddress",
                addressCountry: "Lithuania",
                addressRegion: "LT",
                addressLocality: "Vilnius",
                postalCode: "01112",
                streetAddress: "J. Jasinskio g. 11",
                description: "This is a very good school"
            },
            url: "https://www2389a.vu.lt/",
            logo: "",
            telephone: "+37052496517",
            email: "rastine@daukanto.vilnius.lm.lt",
        }
    }

    return (
        <div className="bg-white">
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
            <ContextProvider>
                <Routes>
                    <Route path="/dokumentas/:slug" element={<Document />} />
                    <Route
                        path="/lt/*"
                        element={
                            <>
                                <Popups />
                                <Header structuredData={structuredData}/>
                                <Main />
                                {isLoadFooter ? <Footer structuredData={structuredData}/> : <></>}
                            </>
                        }
                    />
                    <Route
                        path="/en/*"
                        element={
                            <>
                                <Popups />
                                <Header structuredData={structuredData}/>
                                <Main />
                                {isLoadFooter ? <Footer structuredData={structuredData}/> : <></>}
                            </>
                        }
                    />
                    <Route
                        path="/*"
                        element={<Navigate to="/lt"/>}
                    />
                  
                </Routes>
            </ContextProvider>
        </div>
    );
}

export default App;
