import React, { lazy, Suspense, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import MainContext from '../../Context';

import '../../App.css';

import MainPage from './MainPage';
import Page from './Page';
import NotFound from './NotFound';
import Loading from '../SpinningCircle';

const Documents = lazy(() => import('./Documents'));
const Galleries = lazy(() => import('./Galleries'));
const Gallery = lazy(() => import('./Gallery'));
const Events = lazy(() => import('./Events'));
const Event = lazy(() => import('./Event'));
const OldEvents = lazy(() => import('./OldEvents'));

export default function Main() {
    const { mainIsLoading } = useContext(MainContext);

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <CSSTransition
                //in={!mainIsLoading}
                in={true}
                timeout={5000}
                classNames={'mainSection'}
            >
                <main className={'mainSection min-h-[84vh] my-[30px] mx-2'}>
                    <Routes>
                        <Route path="/" element={<MainPage />} />

                        <Route path="/:slug" element={<Page />} />
                        <Route path="/:slug/:slug" element={<Page />} />
                        <Route path="/:slug/:slug/*" element={<NotFound />} />

                        <Route
                            path="/įvykiai"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Events />
                                </Suspense>
                            }
                        />

                        <Route
                            path="/įvykiai/:slug"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Event />
                                </Suspense>
                            }
                        />
                        <Route
                            path="seni-įvykiai"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <OldEvents />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/galerija"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Galleries />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/galerija/:slug"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Gallery />
                                </Suspense>
                            }
                        />

                        <Route
                            path="/dokumentai"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Documents />
                                </Suspense>
                            }
                        />
                    </Routes>
                </main>
            </CSSTransition>
        </>
    );
}
